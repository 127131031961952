export const genders = ["female", "male"] as const

export const ethnicities = [
  "Causasian",
  "African",
  "Indian",
  "Asian",
  "Arabic",
  "Latina",
] as const

export const bodyTypes = [
  "Skinny",
  "Slim",
  "Athletic",
  "Muscular",
  "Curvy",
  "Chubby",
] as const

export const skinTones = [
  { name: "Pale", hex: "bg-yellow-100" },
  { name: "Fair", hex: "bg-yellow-200" },
  { name: "Olive", hex: "bg-yellow-500" },
  { name: "Tan", hex: "bg-yellow-600" },
  { name: "Brown", hex: "bg-yellow-800" },
  { name: "Ebony", hex: "bg-yellow-950" },
] as const

export const femaleHairStyles = [
  "Bob Cut",
  "Pixie Cut",
  "Top Knot",
  "Pigtails",
  "Twintails",
  "Dreadlocks",
  "Bun",
  "Ponytail",
] as const

export const maleHairStyles = [
  "Bald",
  "Pompadour",
  "Undercut",
  "Buzz cut",
  "Long",
  "Curly",
] as const

export const hairColors = [
  { name: "Blonde", hex: "bg-yellow-200" },
  { name: "Brown", hex: "bg-[#8B4513]" },
  { name: "Black", hex: "bg-black" },
  { name: "Ginger", hex: "bg-orange-600" },
  { name: "Pink", hex: "bg-pink-500" },
  { name: "Blue", hex: "bg-blue-400" },
  { name: "Grey", hex: "bg-[#BEBEBE]" },
] as const

export const eyeColors = [
  { name: "Blue", hex: "bg-blue-400" },
  { name: "Green", hex: "bg-[#556B2F]" },
  { name: "Brown", hex: "bg-[#8B4513]" },
  { name: "Grey", hex: "bg-[#BEBEBE]" },
] as const

export const traits = [
  "Shy",
  "Introvert",
  "Extravert",
  "Innocent",
  "Ambitious",
  "Driven",
  "Stylish",
  "Free-spirit",
  "Temptress",
  "Lover",
  "Nympho",
  "Submissive",
  "Kind-hearted",
  "Fun-loving",
  "Jester",
  "Confident",
  "Caregiver",
  "Calm",
  "Sage",
  "Experimenter",
  "Dominant",
  "Mean",
  "Cocky",
] as const

export const occupations = [
  "Accountant",
  "Actor",
  "Architect",
  "Artist",
  "Astronaut",
  "Attorney",
  "Biologist",
  "Biomedical Engineer",
  "Botanist",
  "Business Analyst",
  "Chef",
  "Civil Engineer",
  "Composer",
  "Data Analyst",
  "Dentist",
  "Designer",
  "Developer",
  "Dietitian",
  "Doctor",
  "Economist",
  "Electrician",
  "Engineer",
  "Environmental Scientist",
  "Farmer",
  "Firefighter",
  "Graphic Designer",
  "Hairdresser",
  "Historian",
  "Illustrator",
  "Journalist",
  "Lawyer",
  "Librarian",
  "Marine Biologist",
  "Marketing Manager",
  "Mathematician",
  "Mechanic",
  "Meteorologist",
  "Nurse",
  "Nutritionist",
  "Optometrist",
  "Painter",
  "Pharmacist",
  "Photographer",
  "Physician",
  "Physicist",
  "Pilot",
  "Plumber",
  "Politician",
  "Psychologist",
  "Public Relations Specialist",
  "Real Estate Agent",
  "Research Scientist",
  "Sales Manager",
  "Social Worker",
  "Software Engineer",
  "Statistician",
  "Surgeon",
  "Teacher",
  "Technical Writer",
  "Therapist",
  "Translator",
  "Urban Planner",
  "Veterinarian",
  "Web Developer",
  "Welder",
  "Writer",
  "Yoga Instructor",
  "Zoologist",
  "Air Traffic Controller",
  "Animator",
  "Biochemist",
  "Cartographer",
  "Criminal Investigator",
  "Dietary Manager",
  "Economist",
  "Film Director",
  "Geologist",
  "Health Educator",
  "Interior Designer",
  "Logistician",
  "Marine Engineer",
  "Network Administrator",
  "Occupational Therapist",
  "Paralegal",
  "Quality Assurance Tester",
  "Radiologic Technologist",
  "Sales Representative",
  "Technical Support Specialist",
  "UX/UI Designer",
  "Veterinary Technician",
  "Wind Turbine Technician",
  "Biomedical Technician",
  "Cloud Architect",
  "Cybersecurity Analyst",
  "Database Administrator",
  "Epidemiologist",
  "Game Developer",
  "Human Resources Manager",
  "Investment Banker",
  "Jeweler",
  "Knowledge Manager",
  "Landscape Architect",
  "Market Research Analyst",
]

export const interests = [
  "Photography",
  "Cooking",
  "Reading",
  "Traveling",
  "Gardening",
  "Painting",
  "Writing",
  "Hiking",
  "Cycling",
  "Fishing",
  "Dancing",
  "Yoga",
  "Meditation",
  "Knitting",
  "Gaming",
  "Bird Watching",
  "Astronomy",
  "Stamp Collecting",
  "Coin Collecting",
  "Playing Guitar",
  "Playing Piano",
  "Running",
  "Swimming",
  "Martial Arts",
  "Skiing",
  "Snowboarding",
  "Surfing",
  "Scuba Diving",
  "Sailing",
  "Rock Climbing",
  "Baking",
  "Origami",
  "Calligraphy",
  "Scrapbooking",
  "Volunteering",
  "Pet Training",
  "Wine Tasting",
  "Homebrewing",
  "Pottery",
  "Sculpting",
  "Model Building",
  "Chess",
  "Board Games",
  "Video Games",
  "Podcasting",
  "Blogging",
  "Vlogging",
  "Magic",
  "Theater",
  "Acting",
  "Stand-up Comedy",
  "Geocaching",
  "Genealogy",
  "Astrology",
  "DIY Projects",
  "Home Improvement",
  "Automotive Repair",
  "Tech Gadgets",
  "Robotics",
  "3D Printing",
  "Programming",
  "Learning Languages",
  "Interior Design",
  "Fashion Design",
  "Jewelry Making",
  "Makeup Artistry",
  "Candle Making",
  "Perfume Making",
  "Tea Tasting",
  "Coffee Brewing",
  "Parkour",
  "Bubble Tea Making",
  "Digital Art",
  "Graphic Design",
  "Animation",
  "Virtual Reality",
  "Augmented Reality",
  "Electric Vehicles",
  "Sustainable Living",
  "Minimalism",
  "Urban Exploration",
  "Street Photography",
  "Nature Photography",
  "Macro Photography",
  "Black and White Photography",
  "Film Photography",
  "Documentary Filmmaking",
  "Short Films",
  "Feature Films",
  "Music Production",
  "DJing",
  "Songwriting",
  "Instrumental Music",
  "Electronic Music",
  "Classical Music",
  "Jazz Music",
  "Folk Music",
  "World Music",
  "Bird Photography",
  "Pet Photography",
  "Travel Blogging",
  "Fantasy Sports",
  "Aquarium Keeping",
  "Beekeeping",
  "Leatherworking",
  "Tattoo Art",
  "Magic Tricks",
  "Collecting Antiques",
  "Electric Guitar Playing",
  "Voice Acting",
  "Stand-up Comedy",
  "Origami Art",
  "Tattoo Collecting",
  "Fantasy Writing",
  "Historical Reenactment",
  "Puzzle Solving",
  "Leather Crafting",
]

export const femaleFullNames = [
  "Emma Johnson",
  "Olivia Smith",
  "Ava Brown",
  "Isabella Davis",
  "Sophia Miller",
  "Mia Wilson",
  "Charlotte Moore",
  "Amelia Taylor",
  "Harper Anderson",
  "Evelyn Thomas",
  "Abigail Jackson",
  "Emily White",
  "Ella Harris",
  "Elizabeth Martin",
  "Camila Thompson",
  "Luna Garcia",
  "Sofia Martinez",
  "Avery Robinson",
  "Mila Clark",
  "Aria Rodriguez",
  "Scarlett Lewis",
  "Penelope Lee",
  "Layla Walker",
  "Chloe Hall",
  "Victoria Allen",
  "Madison Young",
  "Eleanor Hernandez",
  "Grace King",
  "Nora Wright",
  "Riley Scott",
  "Zoey Green",
  "Hannah Adams",
  "Hazel Baker",
  "Lily Gonzalez",
  "Ellie Nelson",
  "Violet Carter",
  "Lillian Mitchell",
  "Zoe Perez",
  "Stella Roberts",
  "Aurora Turner",
  "Natalie Phillips",
  "Emilia Campbell",
  "Everly Parker",
  "Leah Evans",
  "Aubrey Edwards",
  "Willow Collins",
  "Addison Stewart",
  "Lucy Sanchez",
  "Audrey Morris",
  "Bella Rogers",
  "Nova Reed",
  "Brooklyn Cook",
  "Paisley Morgan",
  "Savannah Bell",
  "Claire Murphy",
  "Skylar Bailey",
  "Isla Rivera",
  "Genesis Cooper",
  "Naomi Richardson",
  "Elena Cox",
  "Caroline Howard",
  "Eliana Ward",
  "Anna Torres",
  "Maya Peterson",
  "Valentina Gray",
  "Ruby Ramirez",
  "Kennedy James",
  "Ivy Watson",
  "Kinsley Brooks",
  "Mackenzie Kelly",
  "Alexa Sanders",
  "Ariana Price",
  "Aaliyah Bennett",
  "Cora Wood",
  "Gabriella Barnes",
  "Serenity Ross",
  "Autumn Henderson",
  "Piper Coleman",
  "Savanna Jenkins",
  "Willow Perry",
  "Bella Powell",
  "Mila Long",
  "Genesis Patterson",
  "Samantha Hughes",
  "Maria Flores",
  "Adeline Washington",
  "Leilani Butler",
  "Vivian Simmons",
  "Madeline Foster",
  "Reagan Gonzales",
  "Delilah Bryant",
  "Arya Alexander",
  "Emery Russell",
  "London Griffin",
  "Molly Diaz",
  "Isabelle Hayes",
  "Julia Myers",
  "Jade Ford",
  "Peyton Hamilton",
]

export const maleFullNames = [
  "Liam Johnson",
  "Noah Smith",
  "Oliver Brown",
  "Elijah Davis",
  "James Miller",
  "William Wilson",
  "Benjamin Moore",
  "Lucas Taylor",
  "Henry Anderson",
  "Alexander Thomas",
  "Mason Jackson",
  "Michael White",
  "Ethan Harris",
  "Daniel Martin",
  "Jacob Thompson",
  "Logan Garcia",
  "Jackson Martinez",
  "Levi Robinson",
  "Sebastian Clark",
  "Mateo Rodriguez",
  "Jack Lewis",
  "Owen Lee",
  "Theodore Walker",
  "Aiden Hall",
  "Samuel Allen",
  "Joseph Young",
  "John Hernandez",
  "David King",
  "Wyatt Wright",
  "Matthew Scott",
  "Luke Green",
  "Asher Adams",
  "Carter Baker",
  "Julian Gonzalez",
  "Grayson Nelson",
  "Leo Carter",
  "Jayden Mitchell",
  "Gabriel Perez",
  "Isaac Roberts",
  "Lincoln Turner",
  "Anthony Phillips",
  "Hudson Campbell",
  "Dylan Parker",
  "Ezra Evans",
  "Thomas Edwards",
  "Charles Collins",
  "Christopher Stewart",
  "Jaxon Sanchez",
  "Maverick Morris",
  "Josiah Rogers",
  "Isaiah Reed",
  "Andrew Cook",
  "Elias Morgan",
  "Joshua Bell",
  "Nathan Murphy",
  "Caleb Bailey",
  "Ryan Rivera",
  "Adrian Cooper",
  "Miles Richardson",
  "Eli Cox",
  "Nolan Howard",
  "Christian Ward",
  "Aaron Torres",
  "Cameron Peterson",
  "Ezekiel Gray",
  "Colton Ramirez",
  "Luca James",
  "Landon Watson",
  "Hunter Brooks",
  "Jonathan Kelly",
  "Santiago Sanders",
  "Axel Price",
  "Easton Bennett",
  "Cooper Wood",
  "Jeremiah Barnes",
  "Angel Ross",
  "Roman Henderson",
  "Connor Coleman",
  "Jameson Jenkins",
  "Robert Perry",
  "Greyson Powell",
  "Jordan Long",
  "Ian Patterson",
  "Carson Hughes",
  "Jaxson Flores",
  "Leon Washington",
  "Dominic Butler",
  "Austin Simmons",
  "Everett Foster",
  "Brooks Gonzales",
  "Xavier Bryant",
  "Kai Alexander",
  "Jose Russell",
  "Parker Griffin",
  "Adam Diaz",
  "Jace Hayes",
  "Nathaniel Myers",
  "George Ford",
  "Emmett Hamilton",
  "Micah Graham",
  "Theodore Sullivan",
  "Silas Wallace",
  "Ezra Woods",
  "Zachary Cole",
  "Ashton West",
  "Easton Jordan",
  "Bryson Owens",
  "Thomas Reynolds",
  "Harrison Fisher",
  "Kai Harrison",
  "Mateo Ellis",
  "Maxwell Harrison",
]
